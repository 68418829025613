import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import storage from '../../../framework/src/StorageProvider';

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/APICall";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
import i18n from "../../../components/src/i18next/i18n";
export interface Address {
  id: string;
  attributes: {
    address_name: string;
    is_default: boolean;
    name: string;
    phone_number: string;
    street: string;
    block: string;
    area: string;
    city: string;
    zipcode: string;
    country_code:string;
  };
}

interface AddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  floor: string | null;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
interface AddressData {
  id: string;
  type: string;
  attributes: AddressAttributes;
}
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';


interface UpdateAddressResponse {
  data: AddressData;
  meta: {
      message: string;
  };
}
interface updatedError {
  errors: {
    contact_number: string
  }[]
}
export interface OrderItemAttributes {
  quantity: number;
  unit_price: string;
  total_price: string;
  reason_of_rejection?: string | null;
  status: string;
  catalogue_name: string;
  brand_name: string;
  catalogue_variant_color: string;
  catalogue_variant_sku: string;
  store_name: string;
  catalogue_variant_size: string;
  catalogue_variant_front_image?: string;
  catalogue_variant_back_image?: string;
  catalogue_variant_side_image?: string;
  driver?: string | null;
}

export interface ErrorMessage {
  errors: [
    {
      token?: string;
    }
  ];
}

interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

export interface DeliveryAddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zip_code: string;
  area: string;
  block: string;
  city: string | null;
  house_or_building_number: string;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

export interface DeliveryAddress {
  id: string;
  type: string;
  attributes: DeliveryAddressAttributes;
}

export interface OrderSummaryAttributes {
  id: number;
  currency_logo: string;
  order_number: string;
  title: string;
  amount: string | null;
  order_item_count: number;
  account_id: number;
  coupon_code_id: string | null;
  delivery_address_id: number;
  sub_total: string;
  total: string;
  status: string;
  custom_label: string | null;
  applied_discount: string;
  cancellation_reason: string | null;
  order_date: string | null;
  is_gift: boolean;
  placed_at: string | null;
  confirmed_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  source: string | null;
  shipment_id: string | null;
  delivery_charges: string | null;
  tracking_url: string | null;
  schedule_time: string | null;
  payment_failed_at: string | null;
  payment_pending_at: string | null;
  returned_at: string | null;
  tax_charges: string;
  deliver_by: string | null;
  tracking_number: string | null;
  is_error: boolean;
  delivery_error_message: string | null;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: string;
  shipping_discount: string;
  shipping_net_amt: string;
  shipping_total: string;
  total_tax: number;
  created_at: string;
  updated_at: string;
  order_deliver_date: string | null;
  order_deliver_time: string | null;
  delivery_addresses: DeliveryAddress;
  order_return_date: string | null;
  order_return_time: string | null;
  razorpay_order_id: string | null;
  charged: string | null;
  invoice_id: string | null;
  invoiced: string | null;
  order_items: OrderItem[];
  payment_detail: string | null;
}

export interface OrderSummaryData {
  id: string;
  type: string;
  attributes: OrderSummaryAttributes;
}

export interface ApiResponse {
  data: OrderSummaryData;
}
interface ResponseJson {
  data: Address[];
  meta: Meta;
  errors?: {
    contact_number?: string;
    address?: string;
    zipcode?:string;
  }[];
}

interface Meta {
  message: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isDeleteDialogOpen: boolean;
  updateAddresss:string;
  selectedAddressId:string;
  countrySuccess:Array<CountrySuccess>;
  isCountryData:string;
  isChecked:boolean;
  isCheccked:boolean;
  openDropdownId: string | null;
  addressData: Address[];
  showaddressData:any;
  dropdownOpen:boolean;
  stylishBuyer: boolean;
  selectedCountry:CountrySuccess | null;
  formData: {
    name: string,
    area: string,
    block: string,
    street: string,
    house_or_building_number: string,
    zipcode: string,
    city: string,
    country_code: string,
    phone_number: string,
    latitude: string,
    longitude:string,
    address_name: string,
    is_default: any 
  };
  isAlert: boolean;
  alertMsg: string | undefined;
  alertType: AlertType;
  errors:any;
  orderSummary: ApiResponse | null; 
  addressToDelete: string | null;
  AddressResponce:string,
  currencySign: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Shippingaddressvalidation2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteAddressCallID: string = "";
  getAddressCallId: string = "";
  updateApiCallId:string = "";
  showAddressCallId:string = "";
  countryCodeAPIEditCallId:string='';
  AddressApiCallId:string='';
  getcategoriesId: string = "";
  getOrderAPICallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      updateAddresss:"",
      selectedAddressId:"",
      isDeleteDialogOpen:false,
      openDropdownId: null,
      stylishBuyer: false,
      addressData:[],
      showaddressData: [],
      dropdownOpen:false,
      countrySuccess:[{
        numeric_code:"",
        country_full_name:"",
        country_code:"",
        country_flag:""
    }],
    isCountryData:"+965",
    selectedCountry:null,
    isChecked:false,
    isCheccked:false,
    formData: {
      name: "",
      area: "",
      block: "",
      street: "",
      house_or_building_number: "",
      zipcode: "",
      city: "",
      country_code: "+965",
      phone_number: "",
      latitude: "33.755787",
      longitude: "-116.359998",
      address_name: "",
      is_default: false 
    },
    isAlert: false,
    alertMsg: '',
    alertType: 'success' as AlertType,
    errors: {},
    orderSummary: null  ,
    addressToDelete: null,
    AddressResponce:"",
    currencySign: "$"
    
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAddressCallId) {
        const defaultAddress = responseJson.data.find((address: Address) => address.attributes.is_default);

        if (defaultAddress) setStorageData("selectedAddressID", defaultAddress.id);

        this.setState({
            addressData: responseJson.data,
            selectedAddressId: defaultAddress?.id
          });
        }
        if (responseJson && !responseJson.errors) {
          this.apiSucessCall(apiRequestCallId, responseJson);
     } 

     if (apiRequestCallId === this.showAddressCallId) {
      if(responseJson?.data){
        this.setState({
            showaddressData: responseJson.data.attributes,
            isChecked:responseJson.data.attributes.is_default,
            selectedAddressId: responseJson.data.id
          });           
        }
      }
          await this.handleOrderResp(apiRequestCallId, responseJson)
          this.manageUpdateResponce(apiRequestCallId,responseJson)
          this.manageUpdateDefaultResponce(apiRequestCallId,responseJson)
    } 
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  apiSAVFailureCalled = async (responseSCJson: ErrorMessage & {message: string}) => {
    if(responseSCJson.message){
      this.handleRedirectToHome(responseSCJson.message);
    }
    if (responseSCJson.errors[0].token) {
      this.handleRedirectToHome(responseSCJson.errors[0].token);
    }
  }

  handleOrderResp = async (apiRequestCallId: string, responseJson: ApiResponse & ErrorMessage & {message: string}) => {
    if(apiRequestCallId === this.getcategoriesId || apiRequestCallId === this.getOrderAPICallId){
      if(responseJson.data){
      this.setState({ orderSummary: responseJson, currencySign:  responseJson.data.attributes.currency_logo});

      setStorageData("selectOrderId", responseJson.data.attributes.id)
      } else {
        await this.apiSAVFailureCalled(responseJson)
      }
    }
  }

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      isLoader: false,
      alertMsg: message,
      alertType: 'error'
    }), () => {
      setTimeout(() => {
        this.handleShippingAddressValidationRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  handleShippingAddressValidationRedirect = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  toggleDropdown1 = (addressID: string) => {
    this.setState(prevState => ({
      openDropdownId: prevState.openDropdownId === addressID ? null : addressID,
    }));
  };
  handleMakeDefault = (addressID: { id: string; attributes: {is_default: boolean}; }) => {
    const updatedAddresses = this.state.addressData.map((address: Address) => {
      if (address.id === addressID.id) {
        return { ...address, attributes: { ...address.attributes, is_default: true } };
      } else {
        return { ...address, attributes: { ...address.attributes, is_default: false } };
      }
    });
    this.setState({
      showaddressData: {...addressID.attributes,is_default:true},
    });
  this.updateSubmit()
    this.setState({
      addressData: updatedAddresses,
      openDropdownId: null, 
    });
  };
  getNavigationEditAddress = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EditShippingaddressvalidation2'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  DeleteAddress=async ( addressID: string | null)=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteAddressCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_address/addresses/${addressID}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `DELETE`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        const updatedAddressData = this.state.addressData.filter(address => address.id !== addressID);
        this.setState({ addressData: updatedAddressData });
        
    }
    oncloseAlert = () => {
      this.setState({ isAlert: false });
  };
    getAddress = async () => {
      const token = await getStorageData("auth-token");
      const header = {
          "Content-Type": configJSON.addAddressApiContentType,
          token: token,
      };
      const requestMessageList = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAddressCallId = requestMessageList.messageId;
      requestMessageList.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpMethodType
      );
      requestMessageList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.addAddressEndPoint
      );
      requestMessageList.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    async componentDidMount() {
      // Customizable Area Start
      const addressID = await storage.get('selectedAddressID')
      super.componentDidMount();
      const stylishBuyer = await getStorageData('exploreBuyer', true);
      if(stylishBuyer){
        this.setState({stylishBuyer})
      }
      this.getAddress();
      this.showAddress(addressID);
      this.getCountryCodeEditApi();
      this.componentRender();
      let result=await storage.get("isAlert")
    let resultMsg=await storage.get("isAlertMsg")
    
    this.setState({isAlert:Boolean(result),alertMsg:resultMsg})
    storage.remove('isAlert')
      // Customizable Area End
    }

    componentRender = async () =>{
      const paymentType = await getStorageData("payment-details");
      if(paymentType==="Direct-Order"){
        await this.getOrderApi();
      } else if(paymentType==="CheckOut"){
        this.categoreySimilar();
      }
    }

    getOrderApi = async () => {
      const orderId = await getStorageData("selectOrderId");
      this.getOrderAPICallId = await apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.httpMethodType,
        endPoint: `${configJSON.orderIdEndPoint}${orderId}`,
        token: await getStorageData("auth-token"),
      })
    }

    showAddress=async ( addressID:string)=>{
      const token = await getStorageData("auth-token");
      const header = {
          token: token,
      };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.showAddressCallId = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_address/addresses/${addressID}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `GET`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
          
      }
    updateSubmit=async ()=>{
      const addressID = await storage.get('addressID')
      const token = await getStorageData("auth-token");
      const header = {
          token: token,
      };
        const formData = new FormData();
          formData.append("name", this.state.showaddressData.name);
          formData.append("area", this.state.showaddressData.area);
          formData.append("block", this.state.showaddressData.block);
          formData.append("street",this.state.showaddressData.street);
          formData.append("house_or_building_number", this.state.showaddressData.house_or_building_number);
          formData.append("zipcode", this.state.showaddressData.zipcode);
          formData.append("city", this.state.showaddressData.city);
          formData.append("country_code", this.state.showaddressData.country_code);
          formData.append("phone_number", this.state.showaddressData.phone_number);
          formData.append("latitude", "33.755787");
          formData.append("longitude", "-116.359998");
          formData.append("address_name", this.state.showaddressData.address_name);
          formData.append("is_default", this.state.showaddressData.is_default);
         
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.updateApiCallId = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_address/addresses/${addressID}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PUT`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    
      toggleDropdown = () => {
        this.setState((prevState) => ({
          dropdownOpen: !prevState.dropdownOpen,
        }));
      };
      apiSucessCall = async (apiRequestCallId: string, responseJson: CountrySuccess[]) => {
        if (apiRequestCallId === this.countryCodeAPIEditCallId) {
            this.setState({
                countrySuccess: responseJson,
            });
        }
       
    };
    handleCountryeditSelect = (country: CountrySuccess, code: string) => {
      this.setState({
        showaddressData:{
          ...this.state.showaddressData,
         country_code:code
        },
          selectedCountry: country,
          dropdownOpen: false,
          isCountryData: code
      });
    };
    handleCountrySelect = (country: CountrySuccess, code: string) => {
      this.setState({
        formData:{...this.state.formData,country_code:code},
          selectedCountry: country,
          dropdownOpen: false,
          isCountryData: code
      });
    };
    apiCallEditForgotPass = async (data: APIPayloadType) => {
      const { contentType, method, endPoint, body } = data;
      const header = {
          "Content-Type": contentType,
      };
      const forgotRequestMessage1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
      forgotRequestMessage1.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
      );
      forgotRequestMessage1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    
      body &&
      forgotRequestMessage1.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
      );
      
      forgotRequestMessage1.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
      );
      runEngine.sendMessage(forgotRequestMessage1.id, forgotRequestMessage1);
      return forgotRequestMessage1.messageId;
    };
    getCountryCodeEditApi = async () => {
      this.countryCodeAPIEditCallId = await this.apiCallEditForgotPass({
          contentType: configJSON.countryApiContentType,
          method: configJSON.httpMethodType,
          endPoint: configJSON.countryCodeEndPoint
      });
    };
    handleInputChange = (event: { target: { name: string; value: string; }; } ) => {
      const { name, value } = event.target;
      this.setState((prevState) => ({
        showaddressData: {
          ...prevState.showaddressData,
          [name]: value
        }
      }));
    };
    handleAddInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value
        }
      }));
    };
    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;   
         this.setState(prevState => ({
         formData: {
          ...prevState.formData,[name]: value.replace(/\D/g, '')
        } 
        }));
    };
    handleToggle = () => {
      if(this.state.isChecked){
        this.setState({ isAlert: true, alertMsg: this.transShippingAddress('Please select a different address as a default'), alertType: 'error'});
    
      }
      else{
    
        this.setState((prevState) => ({
          showaddressData: {
            ...prevState.showaddressData,
            is_default: !prevState.showaddressData.is_default 
          },
        }));
      }
    };
    handleAddToggle = () => {
      this.setState((prevState) => ({
        isCheccked: !prevState.isCheccked,
        formData: {
          ...prevState.formData,
          is_default: !prevState.isCheccked 
        }
      }));
    };
    getNavigationAddAddress = () => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'Shippingaddressvalidation2'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
       
      });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
    placeOrderNavigation = () => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'PaymentDetails'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
       
      });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }


  handlePlaceOrder = () => {
    if (!this.state.selectedAddressId) {
      this.setState({
        isAlert: true,
        alertType: 'error',
        alertMsg: this.transShippingAddress('Please select an address.'),
      });
    } else {
   this.placeOrderNavigation()
    }
  }
    handleUpdateSubmit = async () => {

      const isValid = this.validateEditForm();
      if (isValid) {
        await this.updateSubmit();
      }
    };

    validateEditForm = () => {
      const { showaddressData } = this.state;
      const errors: { [Validatekey: string]: string } = {};
    
     
      const requiredFields = [
        { name: "name", message: this.transShippingAddress("*Please enter a valid full name") },
        { name: "area", message: this.transShippingAddress("*Please enter a valid Area") },
        { name: "block", message: this.transShippingAddress("*Please enter a valid Block") },
        { name: "street", message: this.transShippingAddress("*Please enter a valid Street") },
        { name: "house_or_building_number", message: this.transShippingAddress("*Please enter a valid House number") },
        { name: "zipcode", message: this.transShippingAddress("*Please enter a valid Zipcode") },
        { name: "city", message: this.transShippingAddress("*Please enter a valid City") },
        { name: "country_code", message: this.transShippingAddress("*Please enter a valid Country code") },
        { name: "phone_number", message: this.transShippingAddress("*Please enter a valid Phone number") },
        { name: "latitude", message: this.transShippingAddress("*Please enter a valid Latitude") },
        { name: "longitude", message: this.transShippingAddress("*Please enter a valid Longitude") },
        { name: "address_name", message: this.transShippingAddress("*Please enter a valid Address name") }
      ];
    
      requiredFields.forEach(field => {
        const value = showaddressData[field.name as keyof typeof showaddressData];
        if (typeof value === "string" && value.trim() === "") {
          errors[field.name] = field.message;
        }
      });
    
      this.setState({ errors });
    
      const zipcode = showaddressData.zipcode;
      if (zipcode && !/^\d+$/.test(zipcode)) {
        errors.zipcode = this.transShippingAddress("*Please enter a valid numeric Zipcode");
      }
      
      const house_or_building_number = showaddressData.house_or_building_number;
    if (house_or_building_number && !/^\d+$/.test(house_or_building_number)){
      errors.house_or_building_number = this.transShippingAddress("*Please enter a valid numeric building number");
    }
      return Object.keys(errors).length === 0;
    };
    handleSubmit = async () => {

      const isValid = this.validateForm();
      if (isValid) {
        await this.AddressAdded();
      }
    };
  
    validateForm = () => {
      const { formData } = this.state;
      const errors: { [key: string]: string } = {};
  
      // List of required fields
      const requiredFields = [
        { name: "name", message: this.transShippingAddress("*Please enter a valid full name") },
        { name: "area", message: this.transShippingAddress("*Please enter a valid Area") },
        { name: "block", message: this.transShippingAddress("*Please enter a valid Block") },
        { name: "street", message: this.transShippingAddress("*Please enter a valid Street") },
        { name: "house_or_building_number", message: this.transShippingAddress("*Please enter a valid House number") },
        { name: "zipcode", message: this.transShippingAddress("*Please enter a valid Zipcode") },
        { name: "city", message: this.transShippingAddress("*Please enter a valid City") },
        { name: "country_code", message: this.transShippingAddress("*Please enter a valid Country code") },
        { name: "phone_number", message: this.transShippingAddress("*Please enter a valid Phone number") },
        { name: "latitude", message: this.transShippingAddress("*Please enter a valid Latitude") },
        { name: "longitude", message: this.transShippingAddress("*Please enter a valid Longitude") },
        { name: "address_name", message: this.transShippingAddress("*Please enter a valid Address name")}
      ];
  
      requiredFields.forEach(field => {
        const value = formData[field.name as keyof typeof formData];
        if (typeof value === "string" && value.trim() === "") {
          errors[field.name] = field.message;
        }
      });
  
      this.setState({ errors });
      const zipcode = formData.zipcode;
      if (zipcode && !/^\d+$/.test(zipcode)) {
        errors.zipcode = this.transShippingAddress("*Please enter a valid numeric Zipcode");
      }
      const city = formData.city;
      if (city && !/^[a-zA-Z\s]+$/.test(city)) {
        errors.city = this.transShippingAddress("*Please enter a valid City");
      }
      const phoneNumber = formData.phone_number;
      if (phoneNumber && !/^\d+$/.test(phoneNumber)) {
        errors.phone_number = this.transShippingAddress("*Please enter a valid numeric Phone Number");
      }
     
    const house_or_building_number = formData.house_or_building_number;
    if (house_or_building_number && !/^\d+$/.test(house_or_building_number)){
      errors.house_or_building_number = this.transShippingAddress("*Please enter a valid numeric building number");
    }
      return Object.keys(errors).length === 0;
    };
    AddressAdded=async ()=>{
      const token = await getStorageData("auth-token");
      const header = {
          token: token,
      };
        const formData = new FormData();
          formData.append("name", this.state.formData.name);
          formData.append("area", this.state.formData.area);
          formData.append("block", this.state.formData.block);
          formData.append("street",this.state.formData.street);
          formData.append("house_or_building_number", this.state.formData.house_or_building_number);
          formData.append("zipcode", this.state.formData.zipcode);
          formData.append("city", this.state.formData.city);
          formData.append("country_code", this.state.formData.country_code);
          formData.append("phone_number", this.state.formData.phone_number);
          formData.append("latitude", "33.755787");
          formData.append("longitude", "-116.359998");
          formData.append("address_name", this.state.formData.address_name);
          formData.append("is_default", this.state.formData.is_default);
         
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.AddressApiCallId = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_address/addresses`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `POST`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    
      categoreySimilar = async () => {
        const unique_token = await getStorageData("unique_token");
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            unique_token: unique_token,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getcategoriesId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getUnique_token}=${unique_token}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    handleOpenDeleteDialog = (addressID: string) => {
      this.setState({ isDeleteDialogOpen: true, addressToDelete: addressID });
    };
    
    handleCloseDeleteDialog = () => {
      this.setState({ isDeleteDialogOpen: false, addressToDelete: null });
    };
    handleConfirmDelete = () => {
      this.DeleteAddress(this.state.addressToDelete);
      this.handleCloseDeleteDialog();
    };
    manageUpdateResponce=(apiRequestCallId:string,responseJson:UpdateAddressResponse & updatedError)=>{
      if(apiRequestCallId === this.updateApiCallId){
        this.getAddress();
        this.setState({
          updateAddresss:responseJson?.meta?.message
        })
        if(this.state.updateAddresss === "Address Updated Successfully"){
          storage.set("isAlert",true)
          storage.set("isAlertMsg",this.transShippingAddress('Address Updated successfully'))
          
          this.setState({ isAlert: true, alertMsg: this.transShippingAddress('Address Updated successfully'), alertType: 'success'});
          this.getNavigationAddAddress()
          
        }else if(responseJson?.errors?.length > 0 ){
          responseJson.errors?.forEach(element => {
            if(element?.contact_number){
            this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                phone_number: element.contact_number
              }
            }))
          }
         });
        }
              }}
              getNavigationMessage = () => {
                const message: Message = new Message(getName(MessageEnum.NavigationMessage))
                message.addData(
                  getName(MessageEnum.NavigationTargetMessage),
                  'AddShippingaddressvalidation2' 
                );
                message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
                const raiseMessage: Message = new Message(
                  getName(MessageEnum.NavigationPayLoadMessage)
                );
                raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
                 
                });
                message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
                this.send(message);
              }
              manageUpdateDefaultResponce=(apiRequestCallId:string,responseJson:ResponseJson)=>{
                if (apiRequestCallId === this.AddressApiCallId) {
                 if (responseJson.errors && responseJson.errors[0].contact_number) {
                  this.setState({ isAlert: true, alertMsg: responseJson.errors && responseJson.errors[0].contact_number, alertType: 'error'});
                 }else if (responseJson.errors && responseJson.errors[0].zipcode) {
                  this.setState({ isAlert: true, alertMsg: responseJson.errors && responseJson.errors[0].zipcode, alertType: 'error'});
                 }else
                  if ('errors' in responseJson) {
                    this.setState({ isAlert: true, alertMsg: this.transShippingAddress('Please Enter valid data'), alertType: 'error'});
                    
                  }else{
                    storage.set("isAlert",true)
                    storage.set("isAlertMsg", this.transShippingAddress('Address Created Successfully'))
                  this.getNavigationAddAddress()
                  this.setState({ isAlert: true, alertMsg: this.transShippingAddress('Address Created Successfully'), alertType: 'success'});
                  }
                 
                  }}
            handleCheckboxChange = (addressID: string) => {
              this.setState({ selectedAddressId: addressID });
              storage.set('selectedAddressID', addressID);
            };
              
            transShippingCart = (pdKey: string) => {
              return i18n.t(pdKey, { ns: "shoppingCart" })
          }

          transShippingAddress = (transKey: string) => {
            return i18n.t( transKey, {ns: "address"});
          }
          

  // Customizable Area End
}
