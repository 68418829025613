import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import storage from '../../../framework/src/StorageProvider';
import React, { RefObject, createRef ,ChangeEvent} from "react";
import i18n from "../../../components/src/i18next/i18n";
interface Variant {
  sku: string;
  price: number;
  id:number;
  stock_qty: number;
}
type UserRole = {
  userType: string;
  language: string;
  currency: string;
};
interface ResponseData {
  "errors": [{ token: string }];
  "data": [
    {
      "id": string;
      "type": string;
      "attributes": {
        "name": string;
        "description": string;
        "primary_image": string;
        "primary_price": string;
        "is_wishlist": false;
        "currency_logo": string;
      }
    }]
  meta: {
    total_pages: number,
    next_page: number,
    current_page:number
  }
}

interface Category {
  id: string | undefined;
  type: string;
  subSubData: SubCategory[] | undefined
  attributes: {
    id: number | undefined;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
  subSubSubData?: SubCategory[]
}
type imageView = 'front' | 'back' | 'side'
interface SubCategory {
  id: string | number;
  type: string;
  subSubSubData?: SubsubsubCategory[]
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

interface SubsubsubCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}
interface GridImage {
  name: string;
  imgUrlLink: string;
  active: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  likeWish: boolean
  showError: string
  showAlert: boolean
  catalogueDataLoading: boolean
  currencySign: string;
  pageNum: number
  totalPage: number
  hasmore: boolean
  openModel: boolean
  openModelCsv: boolean
  fileName: string
  openMainForm: boolean
  genderSelect: boolean
  genderValue: string
  genValue:string
  productDescription: string
  productCare: string
  fit: string
  material: string
  brand: string
  brand1:string
  productName: string
  PrdName: string
  clothSelect: boolean
  clothValue: string
  categorySelect: boolean
  categoryValue: string
  categoryValue1: string
  listed: any
  linkStatus: number
  totalSteps: number
  personName: string[]
  selectedSizeIds: string[]
  sizevarient: string[]
  sizeMulSelect: boolean
  colorArry: string[]
  selectedColorIds: any
  colorSel: boolean
  category: Category[]
  category1: Category[]
  subsubCategory: Category[]
  varient_size: any
  varient_color: any
  create_vari: any
  variantCount: any
  create_variEdit: any
  selectId: number | undefined;
  sortedCategoryLoading: boolean,
  subCategoryLoading: boolean,
  filterStart: boolean,
  showScorl: boolean;
  categoryIdd:any;
  categoryValueId:any;
  subsubcategoryValueId:any;
  selectedCategory: Category | null;
  selectedCategory1: Category | null;
  subsubSelectId: number | undefined;
  variants: any;
  selectedView: imageView; 
  frontImage?: string | null;
  backImage?: string | null;
  sideImage?: string | null;
  blackSelectedImage: any
  whiteSelectedImage: any
  blueSelectedImage: any
  blackActiveGridImage: string
  whiteActiveGridImage: string
  blueActiveGridImage: string
  storeList: any;
  find_name:any;
  sku_change: any;
  createResponce:any;
  catalogueDetail: any;
  selectedStores: any;
  selectAll: boolean;
  errors: { [key: string]: string };
  isVariantCreated: boolean,
  validationErrors: string[],
  createVariantError:string,
   variant_color: string;
   [key: string]: any;
   gridImages:any;
   isSuccess: boolean;
   isAlert: boolean;
   alertType: AlertType;
   alertMsg:string;
   selectedItem: number;
   arrowDisable: boolean;
   productId:any
   editProductDetail:boolean;
  userRole: UserRole | null;
  roleData:string,
   selectedColorIdsPrev:any
   selectedSizeIdsPrev:any
   create_var_prev:any
   isColorDropdownOpen: boolean;
   isSizeDropdownOpen: boolean; // New state for size dropdown
   selectedColor: string;
   selectedColorId:number
   selectedSize: string; // New state for selected size
   sizeError: boolean,
  colorError: boolean,
  searchQuery:string;
  editstoreList:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddProductCatalougeController extends BlockComponent<Props, S, SS> {
   // Customizable Area Start
   elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

   getCatalogueApiCallId: string
  getCategoryCallId: string = "";
  getSub_categoriesCallId: string = "";
  getSubSub_categoriesCallId: string = "";
  getVarientSizeCallId: string = "";
  getVarientColorCallId: string = "";
  getVarientCallId: string = "";
  getStoreCallId: string = "";
  getFindNameCallId: string = "";
  getskuChangeCallId: string = "";
  catalogueApiCallId: string = "";
  createCatalogueCallId: string = "";
  fileInputRef: any = React.createRef();
  createMainApiCallId: string ="";
  updateMainApiCallId: string ="";
  getDetailApiCallId: string ="";
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   // Customizable Area Start
   // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      category: [],
      arrayHolder: [],
      searchQuery:"",
      token: "",
      currencySign: "$",
      likeWish: false,
      showError: '',
      showAlert: false,
      catalogueDataLoading: true,
      pageNum: 1,
      totalPage: 0,
      hasmore: true,
      openModel: false,
      openModelCsv: false,
      fileName: '',
      openMainForm: false,
      genderSelect: false,
      genderValue: 'male',
      genValue:'',
      productDescription: '',
      productCare: '',
      fit: '',
      material: '',
      brand: '',
      brand1:'',
      productName: '',
      PrdName:'',
      clothSelect: false,
      clothValue: "",
      categorySelect: false,
      categoryValue: "",
      createResponce:'',
      categoryValue1: "",
      listed: "listed",
      linkStatus: 1,
      totalSteps: 0,
      personName: [],
      selectedSizeIds: [],
      sizevarient: [],
      sizeMulSelect: false,
      colorArry: [],
      selectedColorIds: '',
      colorSel: false,
      selectId: 0,
      sortedCategoryLoading: false,
      subCategoryLoading: false,
      filterStart: false,
      showScorl: false,
      categoryIdd: null,
      categoryValueId: null,
      subsubcategoryValueId:null,
      selectedCategory: null,
      selectedCategory1: null,
      category1: [],
      subsubCategory: [],
      varient_size: [],
      varient_color: [],
      selectedStores: [],
      create_vari: [],
      variantCount: 0,
      create_variEdit:[],
      storeList: [],
      editstoreList: [],
      find_name: '',
      sku_change: '',
      catalogueDetail:'',
      subsubSelectId: 0,
      variants: [],
      isAlert: false,
      selectedView: 'front', // default view
      frontImage: null,
      backImage: null,
      sideImage: null,
      selectAll: false,
      alertType: 'success' as AlertType,
      alertMsg:'',
      errors: {},
      createVariantError:"",
      isVariantCreated: false,
      selectedColors: [], 
      gridImages: [
      ],
   
      variant_color: '',
      blackActiveGridImage: "",
      whiteActiveGridImage: "",
      blueActiveGridImage: "",
      blackSelectedImage: "",
      whiteSelectedImage: "",
      blueSelectedImage: "",
      validationErrors: [],
      isSuccess: false,
      editProductDetail:false,
      arrowDisable: false,
      selectedItem: 0,
      productId:"",
      userRole:null,
      selectedColorIdsPrev:"",
      selectedSizeIdsPrev:"",
   create_var_prev:"",
   roleData:"",
   isColorDropdownOpen: false,
   isSizeDropdownOpen: false, // Initialize size dropdown state
   selectedColor: '',  // Initially empty, updated in componentDidMount
   selectedColorId: 0,  // Initially empty, updated in componentDidMount
   selectedSize: '', 
   sizeError: false,
  colorError: false,
    };
    // Customizable Area End
   
    // Customizable Area Start
    this.getCatalogueApiCallId = ''
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
  // Customizable Area Start
     this.categoryTole()
    
     
    this.getCatalogueListRequest()
    this.varientSize()
    
    this.getCatalogueDetail(await storage.get('productId'))
   this.setState({productId:await storage.get('productId')})
   this.setState({editProductDetail: localStorage.getItem("editProduct")=="true"})
   const firstColor =
   this.state.catalogueDetail?.attributes?.catalogue_variants[0]?.attributes
     ?.catalogue_variant_color?.name || 'Color';

 const firstSize =
   this.state.catalogueDetail?.attributes?.catalogue_variants[0]?.attributes
     ?.catalogue_variant_size?.name || 'Size';

 this.setState({
   selectedColor: firstColor, // Set the default selected color
   selectedSize: firstSize,   // Set the default selected size
 });
    this.varientColor()
    
    const userRole = await getStorageData('userRole', true);
    
    this.setState({userRole})
   
    
    this.setState({ totalSteps :this.totalSteps(userRole,localStorage.getItem("editProduct")=="true")});
    userRole.userType !== 'stylist' &&this.getStore();

    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })

 

    const selectedColors = JSON.parse(storage.get('selectedColors') || '[]');
    this.setState({ selectedColors });
  
    
  
  
   
  // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ token: from });
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.handleCatalogueAPICallId(apiRequestCallId, responseJson)
      } else {
        this.setState({ showError: this.transAddProductCatalogue('Something went wrong.') })
      }

    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoryCallId !== null &&
      this.getCategoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ category: responseJsonCategory.data, sortedCategoryLoading: false })
    }

    this.getSub_categoriesCall(message);
    this.getSubSub_categories(message);
    this.manageVarientSizeResponce(message);
    this.manageVarientColorResponce(message);
    this.manageVarientCreateResponce(message);
    this.managestoreListResponce(message);
    this.manageFindNameResponce(message);
    this.manageSkuResponce(message);
    this.manageCreateResponce(message);
    this.manageDetailResponce(message);

    // Customizable Area End
  }

  // Customizable Area Start
   totalSteps = (userRole:{userType:string},condition:boolean)=>{
    if(userRole.userType === 'stylist'){
      if(condition){
        return 4;
      }
      else{
        return 5;
      }
    }
    else{
      if(condition){
        return 5;
      }
      else{
        return 6;
      }
    }
  }
  getCatalogueListRequest = async () => {
    if (!this.state.pageNum) {
      this.setState({ hasmore: false })
      return
    }

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatalogueList + `&page=${this.state.pageNum}` + `&search=${this.state.searchQuery}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNavigationMessage = (productId:any) => {
    storage?.set('productId',productId)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ProductDetail' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  getNavigationMessageCatalogue = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Catalogue' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getCatalogueDetail = async (productId:any) => {

    const token = await getStorageData("auth-token");
    const userdetails = await getStorageData("userRole", true);
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCatalogueDetail}/${productId}?currency=${userdetails?.currencyLogo === "KWD" ? "dinar": "dollar" }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };


 
  manageDetailResponce = (message: Message) => {
    if (this.isValidDetailResponse(message)) {
      const responseDetail = this.getResponseDetail(message);
      const selectedStoreIds = this.getSelectedStoreIds(responseDetail);
      
      this.setState({ catalogueDetail: responseDetail.data });
  
      if (this.state.editProductDetail) {
        this.updateEditProductState(responseDetail, selectedStoreIds);
      }
    }
  };
  
  isValidDetailResponse = (message: Message) => {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDetailApiCallId !== null &&
      this.getDetailApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  };
  
  getResponseDetail = (message: Message) => {
    return message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  };
  
  getSelectedStoreIds = (responseDetail: any) => {
    return responseDetail.data.attributes.catalogue_variants_with_store.map(
      (variant: any) => variant.store_info?.id
    );
  };
  
  updateEditProductState = (responseDetail: any, selectedStoreIds: any) => {
    const storeList =this.state.storeList;
    const gridImages = this.getGridImages(responseDetail);
    const catalogueVariants = this.getCatalogueVariants(responseDetail);
    const catalogueVariants2 = this.getCatalogueVariants2(responseDetail);
    const personName = this.getPersonName(responseDetail);
    const colorArry = this.getColorArray(responseDetail);
    this.setState({
      selectedStores: selectedStoreIds,
      storeList:responseDetail.data.attributes.catalogue_variants_with_store,
      selectAll: storeList.length === responseDetail.data.attributes.catalogue_variants_with_store.length ,
      catalogueDetail: responseDetail.data,
      gridImages,
      ...this.getProductAttributes(responseDetail),
      selectedColorIds: this.getSelectedColorIds(responseDetail),
      selectedSizeIds: this.getSelectedSizeIds(responseDetail),
      create_vari: catalogueVariants,
      create_variEdit:catalogueVariants,
      selectedColorIdsPrev: this.getSelectedColorIds(responseDetail),
      selectedSizeIdsPrev: this.getSelectedSizeIds(responseDetail),
      create_var_prev: catalogueVariants2,
      personName,
      colorArry
    });
  
    this.triggerSubCategory(responseDetail);
    this.triggerSubSubCategory(responseDetail);
  };
  
  getStoreList = (responseDetail: any) => {
    const uniqueStores = new Set();
    return responseDetail.data.attributes.catalogue_variants_with_store
      .map((variant: any) => ({
        id: variant.store_info?.id,
        attributes: {
          store_name: variant.store_info.attributes?.store_name,
          image: variant.store_info.attributes?.image
        }
      }))
      .filter((store: any) => {
        if (store.id && !uniqueStores.has(store.id)) {
          uniqueStores.add(store.id);
          return true;
        }
        return false;
      });
  };
  
  
  getGridImages = (responseDetail: any) => {
    const variants = responseDetail.data.attributes?.catalogue_variants;
    const colorMap = new Map();
  
    variants &&  variants.forEach((variant: any) => {
      const color = variant.attributes?.catalogue_variant_color?.name;
    
      const newImages = [
        { name: 'Front View', imgUrlLink: variant.attributes.front_image },
        { name: 'Back View', imgUrlLink: variant.attributes.back_image },
        { name: 'Side View', imgUrlLink: variant.attributes.side_image }
      ];
  
      let activeGridImage = 'Front View';
      let selectedImage = variant.attributes.front_image;
  
      if (!selectedImage) {
        if (variant.attributes.back_image) {
          activeGridImage = 'Back View';
          selectedImage = variant.attributes.back_image;
        } else if (variant.attributes.side_image) {
          activeGridImage = 'Side View';
          selectedImage = variant.attributes.side_image;
        }
      }
  
      if (colorMap.has(color)) {
        const existingVariant = colorMap.get(color);
  
        newImages.forEach((image) => {
          const existingImage = existingVariant.images.find((img: any) => img.name === image.name);
          if (!existingImage.imgUrlLink && image.imgUrlLink) {
            existingImage.imgUrlLink = image.imgUrlLink; 
          }
        });
  
        if (!existingVariant.selectedImage) {
          existingVariant.selectedImage = selectedImage;
          existingVariant.activeGridImage = activeGridImage;
        }
      } else {
        colorMap.set(color, {
          color,
          images: newImages,
          selectedImage: selectedImage,
          activeGridImage: activeGridImage, 
        });
      }
    });
  
    return Array.from(colorMap.values());
  };
  
  
  

  
  getCatalogueVariants = (responseDetail: any) => {
    return responseDetail.data.attributes.catalogue_variants.map((variant: any) => {
      const attributes = variant.attributes;
      return {
        id: attributes.id,
        on_sale: attributes.on_sale,
        sale_price: attributes.sale_price,
        stock_qty: attributes.stock_qty,
        price: attributes.price,
        discount_price: attributes.discount_price,
        catalogue_variant_size_id: attributes.catalogue_variant_size_id,
        length: attributes.length,
        catalogue_variant_color_id: attributes.catalogue_variant_color_id,
        breadth: attributes.breadth,
        height: attributes.height,
        sku: attributes.sku,
        low_stock_threshold: attributes.low_stock_threshold,
        is_listed: attributes.is_listed,
        deactivate: attributes.deactivate,
        front_image: attributes.front_image,
        side_image: attributes.side_image,
        back_image: attributes.back_image,
        discounted_price: attributes.discounted_price,
        variant_size: attributes.catalogue_variant_size?.name || null,
        variant_color: attributes.catalogue_variant_color?.name || null,
        created_at: attributes.created_at,
        updated_at: attributes.updated_at,
        catalogue_id: attributes.catalogue_id
      };
    });
  };
  
  // Helper function to map another set of catalogue variants
  getCatalogueVariants2 = (responseDetail: any) => {
    return this.getCatalogueVariants(responseDetail).map((variant:any) => ({
      ...variant,
      block_qty: null,
      remove_front_image: null,
      remove_back_image: null,
      remove_side_image: null
    }));
  };
  
  // Helper function to get person names (sizes)
  getPersonName = (responseDetail: any) : string[]=> {
    const names = responseDetail.data.attributes.catalogue_variants
    .map((item: any) => item.attributes.catalogue_variant_size?.name)
    .filter((name: string | null | undefined): name is string => name !== null && name !== undefined);

  // Use a Set to filter out duplicate names and return as a string array
  return Array.from(new Set(names));
  };
  
  
  getColorArray = (responseDetail: any) : string[] => {
    const names = responseDetail.data.attributes.catalogue_variants
    .map((item: any) =>  item.attributes.catalogue_variant_color?.name )
    .filter((name: string | null | undefined): name is string => name !== null && name !== undefined);

 
    return Array.from(new Set(names));

  };
  

  
  getProductAttributes = (responseDetail: any) => {
    const attributes = responseDetail.data.attributes;
    return {
      productName: attributes.name,
      brand: attributes.brand_name,
      material: attributes.material,
      fit: attributes.fit,
      productCare: attributes.prodcut_care,
      productDescription: attributes.description,
      listed: attributes.list_the_product,
      genderValue: attributes.gender,
      clothValue: attributes.category.attributes.name,
      categoryIdd: attributes.category.attributes.id,
      categoryValue: attributes.sub_category.attributes.name,
      categoryValueId: attributes.sub_category.attributes.id,
      categoryValue1: attributes.sub_sub_category.attributes.name,
      subsubcategoryValueId: attributes.sub_sub_category.attributes.id,

    };
  };
  
  
  
  getSelectedColorIds = (responseDetail: any): string[] => {
    return Array.from(
      new Set(
        responseDetail.data.attributes.catalogue_variants.map(
          (variant: any) => String(variant.attributes.catalogue_variant_color_id)
        )
      )
    );
  };
  
  getSelectedSizeIds = (responseDetail: any): string[] => {
    return Array.from(
      new Set(
        responseDetail.data.attributes.catalogue_variants.map(
          (variant: any) => String(variant.attributes.catalogue_variant_size_id)
        )
      )
    );
  };
  
  
  
  triggerSubCategory = (responseDetail: any) => {
    const category = responseDetail.data.attributes.category.attributes;
    if (category?.name) {
      this.sub_category(category.id);
    }
  };
  
  triggerSubSubCategory = (responseDetail: any) => {
    const subCategory = responseDetail.data.attributes.sub_category.attributes;
    if (subCategory?.name) {
      this.subsubCategorey(subCategory.id);
    }
  };
  
  handleHorizontalScroll = (element: RefObject<HTMLDivElement>, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (element.current) {
        element.current.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        this.setState({ arrowDisable: element.current.scrollLeft === 0 });
      }
    }, speed);
  };

  handleClick = () => {
    this.setState(prevState => ({ selectedItem: Math.max(prevState.selectedItem - 1, 0) }));
    this.handleHorizontalScroll(this.elementRef, 25, 100, -10);
  };

  handleNextClick = () => {
    this.setState(prevState => ({
      selectedItem: Math.min(prevState.selectedItem + 1, this.state.catalogueDetail?.attributes?.catalogue_variants[0]?.attributes?.side_image ? 2 : 1)
    }));
    this.handleHorizontalScroll(this.elementRef, 25, 100, 10);
  };
  handleCatalogueAPICallId = (apiRequestCallId: string, responseJson: ResponseData | {
    errors: [{ token: string }], data: [
      {
        "id": string;
        "type": string;
        "attributes": {
          "name": string;
          "description": string;
          "primary_image": string;
          "primary_price": string;
          "is_wishlist": false;
          "currency_logo": string;
        }
      }]
    meta: {
      total_pages: number,
      next_page: number,
      current_page:number
    }
  }) => {
    if (apiRequestCallId === this.getCatalogueApiCallId) {
      if (responseJson?.data) {
        this.setState({ arrayHolder: this.state.arrayHolder.concat(responseJson?.data) })
        this.setState({ pageNum: responseJson?.meta?.next_page })
        this.setState({ totalPage: responseJson?.meta?.total_pages })
        if(responseJson?.data?.length > 0){
          this.setState({
            currencySign: responseJson.data[0].attributes.currency_logo
          })
        }
      }
      if (responseJson?.errors) {
        this.setState({ arrayHolder: [] })
        this.setState({ catalogueDataLoading: false })
        
      }
      if (responseJson?.errors[0]?.token) {
        this.setState({ showError: responseJson?.errors[0].token + ' Please login again' })
        this.setState({ showAlert: true })
      }
    }
  }
  closeModel = () => {
    
    this.setState({ showAlert: false })
  }

  getSub_categoriesCall = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSub_categoriesCallId !== null &&
      this.getSub_categoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSub_categories = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      this.setState({ category1: responseJsonSub_categories.data })
    }
  }


  formattedDate = () => {
    const newDate = this.state.catalogueDetail.attributes?.catalogue_variants_with_store[0].store_info.attributes?.expected_delivery_time || "";
    if (!newDate) return ""
    const date = new Date(newDate);
    let dateString = date.toDateString().split(' ')
    return `${dateString[0]}, ${dateString[2]} ${dateString[1]} - ${dateString[3]}`
  }
  categoryTole = async () => {
    this.setState({ sortedCategoryLoading: true })

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoryCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categories
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  sub_category = async (categoryId: number | undefined) => {
    this.setState({ selectId: categoryId, subCategoryLoading: true, filterStart: true, showScorl: false })
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSub_categoriesCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sub_categories + categoryId
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  subsubCategorey = async (subsubCategoreyId: number | undefined) => {
    this.setState({ subsubSelectId: subsubCategoreyId, filterStart: true, showScorl: false })
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubSub_categoriesCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sub_sub_categories}=${subsubCategoreyId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  varientSize = async () => {

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVarientSizeCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.varient_size}`
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  getSubSub_categories = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSubSub_categoriesCallId !== null &&
      this.getSubSub_categoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSubSub_categories = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ subsubCategory: responseJsonSubSub_categories.data })

    }
  }

  manageVarientSizeResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVarientSizeCallId !== null &&
      this.getVarientSizeCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSize = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ varient_size: responseJsonSize.data })

    }
  }

  varientColor = async () => {

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVarientColorCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.varient_color}`
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  manageVarientColorResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVarientColorCallId !== null &&
      this.getVarientColorCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonColor = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ varient_color: responseJsonColor.data })

    }
  }
  manageVarientCreateResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVarientCallId !== null &&
      this.getVarientCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseCreate = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const responseArray = Array.isArray(responseJsonseCreate) 
      ? responseJsonseCreate 
      : [responseJsonseCreate];
 // Destructure state variables
const { create_vari, selectedColorIds, selectedSizeIds } = this.state;

// Step 1: Filter the responseArray based on the absence of both color and size IDs in create_vari
const filteredVariants = responseArray.filter((variant) => {
  // Check if the color and size exist in create_vari
  const existsInCreateVari = create_vari.some((existingVariant:any) => 
    existingVariant.catalogue_variant_color_id.toString() === variant.catalogue_variant_color_id.toString() &&
    existingVariant.catalogue_variant_size_id.toString() === variant.catalogue_variant_size_id.toString()
  );

  // If both color and size are not present, we include the variant in the filtered results
  return !existsInCreateVari;
});
const updatedVariantsCreate_vari = create_vari.filter((variant: any) => {
  const isColorSelected = selectedColorIds.includes(variant.catalogue_variant_color_id.toString());
  const isSizeSelected = selectedSizeIds.includes(variant.catalogue_variant_size_id.toString());

  return isColorSelected && isSizeSelected;
});

// Step 2: Update the state with the filtered variants
this.setState((prevState) => ({
  // Add the filtered variants to create_vari
  create_vari: [...updatedVariantsCreate_vari, ...filteredVariants],
  variantCount: [...updatedVariantsCreate_vari, ...filteredVariants].length, // Update the count
  // Store filtered variants separately
  updatedItems: filteredVariants,
  // Also, save the current selected color and size IDs for future reference
  selectedColorIdsPrev: prevState.selectedColorIds,
  selectedSizeIdsPrev: prevState.selectedSizeIds,
}));


    }
  }



  createVarient = async (variant_size_ids: any, variant_color_ids: any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVarientCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.create_varient}?variant_size_ids=[${variant_size_ids}]&variant_color_ids=[${variant_color_ids}]`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }


  
  handleDeleteImage = (color: string, viewName: string) => {
    this.setState((prevState) => ({
      gridImages: prevState.gridImages.map((grid: any) => {
        if (grid.color === color) {
          const updatedImages = grid.images.map((image: any) => {
            if (image.name === viewName) {
              return {
                ...image,
                imgUrlLink: "", 
                file: null,
                active: false,   
              };
            }
            return image; 
          });
  
          const isDeletingActiveImage = grid.activeGridImage === viewName;
  
          return {
            ...grid,
            images: updatedImages,
            selectedImage: isDeletingActiveImage ? '' : grid.selectedImage,
            activeGridImage: isDeletingActiveImage ? '' : grid.activeGridImage,
          };
        }
        return grid;
      })
    }));
  };
  
  

  handleGridImage = (color: string, viewName: string, event:any) => {
    const { gridImages } = this.state;
    const file = event.target.files ? event.target.files[0] : null;
  
    const updatedImages = gridImages.map((grid: any) => {
      if (grid.color === color) {
        const updatedImages = grid.images.map((image: any) => {
          if (image.name === viewName) {
            return {
              ...image,
              imgUrlLink: file ? URL.createObjectURL(file) : "",
              file,
              active: true,
            };
          }
          return image;
        });
  
        return {
          ...grid,
          images: updatedImages,
          selectedImage: file ? URL.createObjectURL(file) : "",
        };
      }
      return grid;
    });
  
    this.setState({ gridImages: updatedImages, selectedImg: file ? URL.createObjectURL(file) : '' });
  };
  
  handleActiveGrid = (color: string, viewName: string) => {
    const { gridImages } = this.state;
    
    const updatedImages = gridImages.map((grid:any) => {
      if (grid.color === color) {
        const selectedImage = grid.images.find((image:any) => image.name === viewName)?.imgUrlLink || "";
  
        const updatedImages = grid.images.map((image:any) => {
          return { 
            ...image, 
            active: image.name === viewName 
          };
        });
  
        return { 
          ...grid, 
          images: updatedImages, 
          activeGridImage: viewName,
          selectedImage,
        };
      }
      return grid;
    });
  
    this.setState({ gridImages: updatedImages, selectedImg: updatedImages.find((grid:any) => grid.color === color)?.selectedImage || "" });
  };
  
  
  getStore = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStoreCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.store_list}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  managestoreListResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStoreCallId !== null &&
      this.getStoreCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseStore = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ storeList: responseJsonseStore.data,editstoreList:responseJsonseStore.data })
    }
  }




  validateForm = async () => {
    const errors: { [key: string]: string } = {};
    const textOnlyPattern = /^[a-zA-Z\s]*$/;

    await this.validateProductName(errors);
    this.validateRequiredFields(errors);
    this.validateTextFields(errors, textOnlyPattern);

    this.setState({ errors });

    return Object.keys(errors).length === 0;
};


validateProductName = async (errors: { [key: string]: string }) => {
  if (!this.state.productName || this.state.productName.trim() === '') {
      errors.productName = this.transAddProductCatalogue('Please enter a valid Product Name.');
  } else {
      await this.findNameOnchange(this.state.productName);
      if (this.state.find_name?.exists) {
          errors.productName = this.transAddProductCatalogue('Product name already exists');
      }
  }
};

validateRequiredFields = (errors: { [key: string]: string }) => {
    const { genderValue, brand, clothValue, categoryValue, categoryValue1 } = this.state;

    if (!genderValue) errors.genderValue = this.transAddProductCatalogue('Please enter a valid Gender');
    if (!brand) errors.brand = this.transAddProductCatalogue('Please enter a valid Brand');
    if (!clothValue) errors.clothValue = this.transAddProductCatalogue('Please enter a valid Category');
    if (!categoryValue) errors.subCategory = this.transAddProductCatalogue('Please enter a valid Sub Category');
    if (!categoryValue1) errors.subSubCategory = this.transAddProductCatalogue('Please enter a valid Sub-Sub category');
    
};

validateTextFields = (errors: { [key: string]: string }, pattern: RegExp) => {
  const { material, fit, productCare, productDescription } = this.state;

  // Custom error messages for specific fields
  this.checkPattern('material', material, pattern, errors, this.transAddProductCatalogue('Please enter a valid Material'));
  this.checkPattern('fit', fit, pattern, errors, this.transAddProductCatalogue('Please enter a valid Fit'));
  this.checkPattern('productCare', productCare, pattern, errors, this.transAddProductCatalogue('Please enter valid Product Care'));

  if (!productDescription) errors.productDescription = this.transAddProductCatalogue('Please enter a valid Description');
};

checkPattern = (
  field: string, 
  value: string, 
  pattern: RegExp, 
  errors: { [key: string]: string }, 
  customErrorMessage?: string 
) => {
  if (!value || !pattern.test(value)) {
      errors[field] = customErrorMessage || this.transAddProductCatalogue(`Please enter a valid ${field}`);
  }
};


validateSkuPriceQuantity = async (): Promise<boolean> => {
  const { create_vari } = this.state;
    let validationErrors: string[] = [];

    const validateField = (fieldValue: any, errorMessage: string): string => {
      return !fieldValue ? errorMessage : "";
    };

    const validateNumericField = (
      fieldValue: any,
      errorMessage: string
    ): string => {
      return isNaN(Number(fieldValue)) ? errorMessage : "";
    };

  const validatePriceField = (priceValue: any): string => {
    if (!priceValue) return this.transAddProductCatalogue("Please fill Price");
    if (isNaN(Number(priceValue))) return this.transAddProductCatalogue("Price must be a number");
    if (Number(priceValue) <= 0) return this.transAddProductCatalogue("Price should be greater than 0");
    return '';
  };

  for (const variant of create_vari) {
    variant.skuError = validateField(variant.sku, this.transAddProductCatalogue("Please fill SKU"));

    variant.quantityError = validateField(variant.stock_qty, this.transAddProductCatalogue("Please fill Quantity")) ||
                            validateNumericField(variant.stock_qty, this.transAddProductCatalogue("Quantity must be a number"));

    variant.priceError = validatePriceField(variant.price);

    if (variant.skuError || variant.quantityError || variant.priceError) {
        validationErrors.push(`${variant.skuError} ${variant.quantityError} ${variant.priceError}`.trim());
    }
}

  this.setState({ create_vari, validationErrors });

  return validationErrors.length === 0;
};




  handleNextStep = async () => {
    const {  userRole, selectedStores } = this.state;
    this.setState({ sizeError: false, colorError: false });

    // Check if size and color are selected only if on stage 2
    let hasError = false;
  
    if (this.state.linkStatus === 2) { // Check if the current stage is 2
      if (this.state.personName.length === 0) {
        this.setState({ sizeError: true });
        hasError = true;
      }
      if (this.state.colorArry.length === 0) {
        this.setState({ colorError: true });
        hasError = true;
      }
    }
    if (this.state.linkStatus === 4) {
      const noImageSelected = this.state.gridImages.some((grid: any) => grid.selectedImage === "");
      if (noImageSelected) {
          this.setState({ imageError: true });
          hasError = true;
      }
  }
    if (hasError) return; // Stop execution if there are errors
    const isValid = await this.validateForm();
    if (isValid) {
      const skuValidation = await this.validateSkuPriceQuantity();
      if (skuValidation) {
        
        const { linkStatus, totalSteps } = this.state; 
  
        if (userRole) {
          if (linkStatus === totalSteps - 1) {
            if (userRole.userType === 'seller' && selectedStores.length === 0) {
              this.setState({
                isAlert: true,
                alertType: 'error',
                alertMsg: this.transAddProductCatalogue('Please select at least one store'),
              });
              return;
            }
            await this.createMainApi();
           
          } else {
            this.setState({ linkStatus: linkStatus + 1 });
          }
        }
      }
    }
   
  };

  handleUpdateNextStep = async () => {

        const { linkStatus, totalSteps,userRole } = this.state; 
        if (userRole) {
        if (linkStatus === totalSteps - 1) {
          if (userRole.userType === 'seller' && this.state.selectedStores.length === 0) {
            this.setState({isAlert:true,alertType:'error',alertMsg: this.transAddProductCatalogue('Please select at least one store')})
            return; 
          }
          await this.updateMainApi();
          this.setState((prevState) => ({
            ...prevState,
            isSuccess: true,
        }), () => {
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        });
        } else {
          this.setState({ linkStatus: linkStatus + 1 });
        }
      }
      
    
  };
  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  
  
  findNameOnchange = async(name:any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFindNameCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.find_name}?name=${name}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  manageFindNameResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFindNameCallId !== null &&
      this.getFindNameCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseFindName = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ find_name: responseJsonseFindName })
    }
  }






  skuOnchange = async(name:any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getskuChangeCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sku_change}?variant_skus=["${name}"]`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  manageSkuResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getskuChangeCallId !== null &&
      this.getskuChangeCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseSku = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ sku_change: responseJsonseSku })
    }
  }

  manageCreateResponce = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createMainApiCallId !== null &&
      this.createMainApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJsonseCreate = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      // Check if there's an SKU error in the response
      let skuErrorMessage = '';
      if (responseJsonseCreate.errors && responseJsonseCreate.errors.length > 0) {
        const skuError = responseJsonseCreate.errors.find((error:any) => error["catalogue_variants.sku"]);
        
        if (skuError) {
          this.setState({
            createResponce: responseJsonseCreate,
            isAlert: true,
            alertType: 'error',
            alertMsg: skuError["catalogue_variants.sku"] // Show the SKU error message
          });
          return; // Stop here if there's an error
        }
        
      }
      else{
        this.setState({ isSuccess: true }, () => {
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        });
      }
      
      // Update the state with the response and the specific SKU error message
      this.setState({ 
        createResponce: responseJsonseCreate,
        skuErrorMessage // Store SKU error message if any
      });
  
      console.log("createResponce", this.state.createResponce);
    }
  }
  


  createMainApi=async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
       token: token,
    };
      const formData = new FormData();
        formData.append("name", this.state.productName);
        formData.append("gender", this.state.genderValue);
        formData.append("brand_name", this.state.brand);
        formData.append("category_id",this.state.categoryIdd);
        formData.append("sub_category_id", this.state.categoryValueId);
        formData.append("sub_sub_category_id", this.state.subsubcategoryValueId);
        formData.append("material", this.state.material);
        formData.append("fit", this.state.fit);
        formData.append("prodcut_care", this.state.productCare);
        formData.append("description", this.state.productDescription);
        formData.append("list_the_product", this.state.listed);
       
       
        this.state.create_vari.forEach((variant:any, index:any) => {
          formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_size_id]`,variant.catalogue_variant_size_id)
formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_color_id]`,variant.catalogue_variant_color_id);
          formData.append(`catalogue_variants_attributes[${index}][stock_qty]`, variant.stock_qty);
          formData.append(`catalogue_variants_attributes[${index}][price]`, variant.price);
          formData.append(`catalogue_variants_attributes[${index}][sku]`, variant.sku);
        });

        this.state.gridImages.forEach((grid: any, index: number) => {
          const frontImage = grid.images.find((image: any) => image.name === 'Front View' && image.file);
          const backImage = grid.images.find((image: any) => image.name === 'Back View' && image.file);
          const sideImage = grid.images.find((image: any) => image.name === 'Side View' && image.file);
      
          if (frontImage) {
           
            formData.append(`catalogue_variants_attributes[${index}][front_image]`, frontImage.file);
          }
          if (backImage) {
            formData.append(`catalogue_variants_attributes[${index}][back_image]`, backImage.file);
          }
          if (sideImage) {
            formData.append(`catalogue_variants_attributes[${index}][side_image]`, sideImage.file);
          }
        });
       
      
     

        const storeIdsAsNumbers = this.state.selectedStores.map((storeId:any) => Number(storeId));
        storeIdsAsNumbers.map((id:number)=>{

          formData.append("store_ids[]", JSON.stringify(id));
        })
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createMainApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_catalogue/catalogues`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `POST`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateMainApi=async ()=>{
      const productIdId=await storage.get('productId');
      const token = await getStorageData("auth-token");
      const header = {
         token: token,
      };
        const formData = new FormData();
        formData.append("fit", this.state.fit);
        formData.append("material", this.state.material);
        formData.append("sub_sub_category_id", this.state.subsubcategoryValueId);
        formData.append("sub_category_id", this.state.categoryValueId);
        formData.append("prodcut_care", this.state.productCare);
        formData.append("category_id",this.state.categoryIdd);
        formData.append("description", this.state.productDescription);
        formData.append("brand_name", this.state.brand);
        formData.append("list_the_product", this.state.listed);
        formData.append("gender", this.state.genderValue);
        formData.append("name", this.state.productName);
         

const prevVariantsMap = new Map(this.state.create_var_prev.map((v: Variant) => [v.id, v]));

this.state.create_vari.forEach((variant: any, index: number) => {
  const prevVariant:any = prevVariantsMap.get(variant.id);

  if (!prevVariant) {
    formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_color_id]`, variant. catalogue_variant_color_id);

  formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_size_id]`,
  variant. catalogue_variant_size_id
 );

    
    formData.append(`catalogue_variants_attributes[${index}][sku]`, variant.sku);
    formData.append(`catalogue_variants_attributes[${index}][price]`, variant.price.toString());
    formData.append(`catalogue_variants_attributes[${index}][stock_qty]`, variant.stock_qty.toString());
  } else {
    formData.append(`catalogue_variants_attributes[${index}][id]`, variant.id); 
    
    if (variant.sku !== prevVariant.sku) {
      formData.append(`catalogue_variants_attributes[${index}][sku]`, variant.sku); 
      }
    if (variant.price !== prevVariant.price) {
      formData.append(`catalogue_variants_attributes[${index}][price]`, variant.price.toString());
    }
    if (variant.stock_qty !== prevVariant.stock_qty) {
      formData.append(`catalogue_variants_attributes[${index}][stock_qty]`, variant.stock_qty.toString());
    }
  }
});

          this.state.gridImages.forEach((grid: any, index: number) => {
            const sideImage = grid.images.find((image: any) => image.name === 'Side View' && image.file);
            const backImage = grid.images.find((image: any) => image.name === 'Back View' && image.file);
            const frontImage = grid.images.find((image: any) => image.name === 'Front View' && image.file);
        
            if (frontImage) {
             
              formData.append(`catalogue_variants_attributes[${index}][front_image]`, frontImage.file);
            }
            if (backImage) {
              formData.append(`catalogue_variants_attributes[${index}][back_image]`, backImage.file);
            }
            if (sideImage) {
              formData.append(`catalogue_variants_attributes[${index}][side_image]`, sideImage.file);
            }
          });
         
        
          
          const storeIdsAsNumbers1 = this.state.selectedStores.map((storeId:any) => Number(storeId));
          storeIdsAsNumbers1.map((idd:number)=>{
  
            formData.append("store_ids[]", JSON.stringify(idd));
          })
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.updateMainApiCallId = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_catalogue/catalogues/${productIdId}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PUT`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    oncloseAlert = () => {
      this.setState({ isAlert: false });
    };
    handleServiceRedirect = (path: string) => {
      const userNavMsg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(userNavMsg);
    };
    handleSearch = (event: any) => {
      const query = event.target.value;
      this.setState({ searchQuery: query ,catalogueDataLoading:true,arrayHolder:[],pageNum:1},()=>this.getCatalogueListRequest());
    }

    handleViewAnalytics = async () => {
      const productId = await getStorageData("productId")
      this.props.navigation.navigate("SellerProductOverview", {navigationBarTitleText: productId} );
    }

    transAddProductCatalogue = (transKey: string) => {
      return i18n.t( transKey, {ns: "catalogue"})
    }
  // Customizable Area End
}
