import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start;
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../components/src/APICall";
import { ErrorMessage } from "../../customform/src/CreateSellerStoreController";
import { ErrorMessageResponse } from "../../customform/src/SellerStoreController";

interface IGetLoyaltyPointState {
  message: string;
  id: number;
  buyer_id: number;
  point_balance: number;
  total_point_earned: number;
  total_point_spent: number;
  created_at: string;
  updated_at: string;
  point_transactions: IPointTransaction[] | null;
}

interface IPointTransaction {
  id: string;
  type: string;
  attributes: {
    id: number;
    point: number;
    transaction_type: string;
    order_management_order_id: number;
    credited_on: string;
    debited_on: string | null;
    transaction_for: string;
    message: string;
  };
}

interface IGetLoyaltyPointResp {
  data: {
    id: string;
    type: string;
    attributes: IGetLoyaltyPointState;
  };
}

interface IGetLoyaltyPointBuyerTranState {
  id: number;
  loyalty_point_id: number;
  order_management_order_id: number;
  transaction_type: string;
  credited_on: string;
  debited_on: string | null;
  order_number: string;
  point: number;
  temp_order_id: string | null;
  transaction_for: string;
  message: string;
}

interface IGetLoyaltyPointBuyerTranResp {
  data: IGetLoyaltyPointBuyerTranState[];
}

interface IGetAllActivePromoCodeState {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    status: string;
    discount: number;
    loyalty_points: number;
    is_redeem: boolean;
  };
}

interface IGetAllActivePromoCodeResp {
  data: IGetAllActivePromoCodeState[];
}

interface IPostPromoCodeResp {
  message: string;
  promo_code: {
    id: number;
    name: string;
    discount_type: string | null;
    redeem_limit: number | null;
    description: string;
    terms_n_condition: number | null;
    max_discount_amount: number | null;
    min_order_amount: number | null;
    from: number | null;
    to: number | null;
    status: string;
    discount: number;
    created_at: string;
    updated_at: string;
    account_id: string | null | number;
    share_token: string | null;
    loyalty_points: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  enableField: boolean;
  fullName: string;
  token: string;
  // Customizable Area Start
  transactionsRecords: IGetLoyaltyPointBuyerTranState[];
  buyerLoyalty: IGetLoyaltyPointState;
  loyaltyPage: boolean;
  redeemPoints: IGetAllActivePromoCodeState[];
  promoCodeId: number;
  redeemDialogOpen: boolean;
  congratulationDialog: boolean;
  isLoading: boolean;
  isAlert: boolean;
  message: string;
  isTransactionLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoyaltysystemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLoyaltyPointCallId: string = "";
  getTransactionDetailCallId: string = "";
  postReedLoyaltyPointCallId: string = "";
  getAllActivePromoCodeCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
       enableField: false,
       // Customizable Area Start
       fullName: "",
      token: "",
      transactionsRecords: [],
      buyerLoyalty: {} as IGetLoyaltyPointState,
      loyaltyPage: false,
      promoCodeId: 0,
      redeemPoints: [],
      redeemDialogOpen: false,
      isLoading: true,
      isAlert: false,
      message: "",
      congratulationDialog: false,
      isTransactionLoading: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.apiSuccess(apiRequestCallId, responseJson);
      } else if (responseJson && (responseJson.errors || responseJson.error)) {
        this.apiFailure(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccess = async (
    apiRequestCallId: string,
    responseJson: IGetLoyaltyPointResp &
      IGetLoyaltyPointBuyerTranResp &
      IGetAllActivePromoCodeResp &
      IPostPromoCodeResp
  ) => {
    const { data } = responseJson || {};
    
    switch (apiRequestCallId) {
      case this.getLoyaltyPointCallId:
        this.setState((prev) => ({
          ...prev,
          buyerLoyalty: data.attributes,
          isLoading: false,
        }));
        break;

      case this.getTransactionDetailCallId:
        this.setState((prev) => ({
          ...prev,
          transactionsRecords: data,
          isLoading: false,
          isTransactionLoading: false,
        }));
        break;

      case this.getAllActivePromoCodeCallId:
        this.setState((prev) => ({
          ...prev,
          redeemPoints: data,
          isLoading: false,
        }));
        break;

      case this.postReedLoyaltyPointCallId:
        if (responseJson.message === "Promo code redeemed successfully!") {
          this.setState(
            { congratulationDialog: true, isTransactionLoading: true },
            async () => await this.handleAllAPI()
          );
        }
        break;

      default:
        break;
    }
  };

  apiFailure = (responseJson: ErrorMessage & ErrorMessageResponse) => {
    if (responseJson.error === "Access denied" || responseJson.error === "Promo code is either inactive or expired" || responseJson.error ===
      "Either you are not authorize to redeem this promo code or Insufficient loyalty points" || (responseJson.error !== "point transaction not found" && responseJson.error)) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        message: responseJson.error,
      }));
    } else if (responseJson.error === "point transaction not found") {
      this.setState((prevState) => ({
        ...prevState,
        isTransactionLoading: false,
      }));
    } else if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        message: responseJson.errors[0].token as string,
      }));
    }
  };

  async componentDidMount() {
    // Customizable Area Start
    const name = await getStorageData("user_data", true);
    const authToken = await getStorageData("auth-token");

    this.setState({
      fullName: `${name?.first_name} ${name?.last_name}`,
      token: authToken,
    });
    await this.handleAllAPI();
    // Customizable Area End
  }
  handleAllAPI = async () => {
    await this.getLoyaltyPointCalled();
    await this.getTransactionDetailCalled();
    await this.getAllActivePromoCodeCalled();
  };

  
    handleDate = (date: string | null) => {
      if (date) {
        const formattedDate = moment(date).format("MMMM D, YYYY");
        return formattedDate;
      } 
      else {
        return ""
      }
    }
  

  handleYesClick = () => {
    this.setState(
      {
        redeemDialogOpen: false,
      },
      () => this.postReedLoyaltyPointCalled()
    );
  };
  handleLoyalty = () => {
    this.setState((prevState) => ({
      loyaltyPage: !prevState.loyaltyPage,
    }));
  };

  handleRedeem = (promoCodeId: number) => {
    this.setState({ redeemDialogOpen: true, promoCodeId: promoCodeId });
  };

  handleRedeemDialogClose = () => {
    this.setState({ redeemDialogOpen: false });
  };

  handleCongratulationClose = () => {
    this.setState({ congratulationDialog: false });
  };

  openLandingPage = () => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  };

  getLoyaltyPointCalled = async () => {
    this.getLoyaltyPointCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getLoyaltyPointEndpoint,
      token: this.state.token,
    });
  };

  getTransactionDetailCalled = async () => {
    this.getTransactionDetailCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getTransactionDetailEndpoint,
      token: this.state.token,
    });
  };

  getAllActivePromoCodeCalled = async () => {
    this.getAllActivePromoCodeCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getAllActivePromoCodeEndpoint,
      token: this.state.token,
    });
  };

  postReedLoyaltyPointCalled = async () => {
    const { token, promoCodeId } = this.state;
    this.postReedLoyaltyPointCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postApiMethodType,
      endPoint: `${configJSON.postReedLoyaltyPointEndpoint}${promoCodeId}`,
      token: token,
    });
  };

  onCloseAlert = () => {
    this.setState((prevState) => ({ ...prevState, isAlert: false }));
  };
  // Customizable Area End
}
