import React from "react";
// Customizable Area Start
import { Box, styled, Grid, TextField, Dialog,
  DialogActions,
  DialogContent, Snackbar, Modal, Typography, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import {  arrow, cross } from "./assets";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

// Customizable Area Start
import {storeSuccessIcon} from "../../customform/src/assets";

import ServiceController ,{Props, configJSON } from "./ServiceController";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import CreatePlan from "./CreatePlan.web";
// Customizable Area End


export default class ServicesList extends ServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
  
    // Customizable Area End
 return (
      // Customizable Area Start
        <ThemeProvider theme={theme}>
      <Grid container>
  <Grid item xl={2} md={2} sm={2} xs={2}>
    {this.state.roleData === "stylist" ? <StylishSidebar navigation={this.props.navigation} /> : <SellerSideBar navigation={this.props.navigation} />}
  </Grid>
  <Grid item xl={10} md={10} sm={10} xs={10}>
  {this.state.roleData === "stylist" && <StylishHeader navigation={this.props.navigation} /> } 
 {this.state.roleData === "seller" && <SellerHeader navigation={this.props.navigation} />}
    <div style={webStyle.mainDiv}>
      {!this.state.openMainForm && (
        <>
          <div style={webStyle.headerDiv}>
            <TitleContainer>
              <img
                src={arrow}
                data-test-id="back-btn-test-id"
                alt="backIcon"
                onClick={() => this.handleServiceRedirect("StylishCatalogue")}
              />
              <Typography className="title">{configJSON.ServiceTitle}</Typography>
            </TitleContainer>


            {this.state.serviceResponce && this.state.serviceResponce?.data?.length > 0 && (
  <CustomBoxSearch>
   


<button
  style={webStyle.btnSetting}
  data-test-id="AddProduct"
  onClick={() => {
    // Remove local storage items before proceeding
    localStorage.removeItem("serviceShowLocalResponce");
    localStorage.removeItem("editPlan");

    // Extract existing plans dynamically from serviceResponce
    const existingPlans = this.state.serviceResponce.data.map(
      (service: any) => service.attributes.name
    );

    // Update state: open modal, set editPlan to false, and store existing plans
    this.setState({
      openModel: true,
      editPlan: false,
      existingPlans,  // Store the extracted plans in state
    });
  }}
  hidden={
    // Check if all three plans exist in the serviceResponce data
    this.state.serviceResponce.data
      .map((service: any) => service.attributes.name)
      .includes("weekly_plan") &&
    this.state.serviceResponce.data
      .map((service: any) => service.attributes.name)
      .includes("monthly_plan") &&
    this.state.serviceResponce.data
      .map((service: any) => service.attributes.name)
      .includes("quarterly_plan")
  }
>
  Create Plan
</button>


    &nbsp;
  </CustomBoxSearch>
)}


          </div>

          <div style={webStyle.contentDivConteiner} id="scrollableDiv">
            <Grid container spacing={2}>
              {this.state.serviceResponce && this.state.serviceResponce?.data?.length > 0 ? (
                this.state.serviceResponce.data.map((service: any) => (
                  <Grid item xs={6} key={service.id}>
                    <CustomBoxContent
                      style={{
                        marginBottom: "20px",
                        padding: "20px",
                        border: "1px solid #375280",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height:'81%'
                      }}
                      data-test-id="getNavigationMessage"
                    >
                     
<div style={webStyle.divofZara}>
  <div style={{ marginLeft: 20, width: "100%" }}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2 style={webStyle.h1First}>
        {(() => {
          switch (service.attributes.name) {
            case 'quarterly_plan':
              return 'Quarterly Service';
            case 'monthly_plan':
              return 'Monthly Service';
            case 'weekly_plan':
              return 'Weekly Service';
            default:
              return service.attributes.name.replace("_", " ");
          }
        })()}
      </h2>
      <h2 style={{ ...webStyle.h1First, marginLeft: "40px" }}>
        {this.state.currencySign} {service.attributes.service_charges}
      </h2>
    </div>
    <hr
      style={{
        border: "1px solid #CBD5E1",
        marginTop: 12,
        marginBottom: 18,
      }}
    />
    <h1 style={webStyle.h1Sec}>
      <span style={{ marginRight: "8px" }}>•</span> {service.attributes.styling_per_week} {service.attributes.styling_per_week === 1 ? 'Styling per week' : 'Stylings per week'}
    </h1>
    <br />
    <h1 style={webStyle.h1Sec}>
      <span style={{ marginRight: "8px" }}>•</span> {service.attributes.discussion_time} {service.attributes.discussion_time === 1 ? 'hour of discussion time' : 'hours of discussion time'}
    </h1>
    <br />
    {service.attributes.voice_call_facility && (
      <h1 style={webStyle.h1Sec}>
        <span style={{ marginRight: "8px" }}>•</span> Voice Call Facility
      </h1>
    )}
    <br />
  </div>
</div>

                  
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto' }}>
                        <button
                          style={{ ...webStyle.btnSetting1, width: "48%", height: "45px" }}
                          data-test-id="RemovePlan"
                          onClick={() => this.handleOpenDeleteDialog(service.id)}
                        >
                          Remove Plan
                        </button>

                        <button
                          style={{ ...webStyle.btnSetting, width: "48%", height: "45px" }}
                          data-test-id="AddProduct2"
                          onClick={() => this.handleEdit(service)}
                        >
                          Edit Plan
                        </button>
                      </div>
                    </CustomBoxContent>
                  </Grid>
                ))
              ) : (
                (!this.state.openMainForm &&
                 <CustomBox>
                  <h1 style={webStyle.titleOfDivShirtHeader}>No service plans added here</h1>
                  <button data-test-id="addProduct" style={webStyle.btnTshirt} onClick={() => {
                    this.setState({ openModel: true, editPlan: false });
                    localStorage.removeItem("serviceShowLocalResponce");
                  }}>Create Plan</button>
                </CustomBox>
             
                
                )
              )}
            </Grid>
          </div>
        </>
      )}
       <DialogContainerCreate data-test-id="handleRedirect1" open={this.state.openMainForm}>
          <CreatePlan navigation={this.props.navigation} onGoBack={this.handleGoBack} id={""} />
        </DialogContainerCreate>
       
    </div>
  </Grid>
</Grid>


        <Snackbar
                    open={this.state.showAlert}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    style={{marginTop:'10px'}}
                    data-testid="oncloseAlert"
                    onClose={()=>this.closeModel()}
                ><Alert variant="filled"
                data-testid="oncloseAlert2"
                onClose={()=>this.closeModel()} severity={ "error"}>{this.state.showError}</Alert></Snackbar>
      <DialogContainer data-test-id="handleRedirect1"  open={this.state.isSuccess} >
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">Plan Created successfully!</Typography>
          </Box>
        </DialogContainer>

      <DiologcontentStyledMain
          open={this.state.isDeleteDialogOpen}
          data-test-id="handleCloseDeleteDialog"
          onClose={this.handleCloseDeleteDialog}
        >
          <DiologcontentStyled>
            <Typography style={{ width: "300px", fontSize: '25px', color: "#375280", fontWeight: 600,marginBottom:"11px", marginLeft: "30px", fontFamily: "Lato" }}>
            Delete Your Service
            </Typography>
            <Typography style={{marginBottom:"18px",  fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" }}>
            Are you sure you want to delete this service?
            </Typography>
          </DiologcontentStyled>
          <DialogActions style={{display:'flex',flexDirection:'row',gap:'10px'}}>
            <Button onClick={this.handleConfirmDelete} style={{
              alignItems: "center",
              fontSize: "15px",
              justifyContent: "center",
              fontWeight: 500,
              display: "flex",
              color: "white",
              backgroundColor: "#F87171",
              border: "1px solid #CCBEB1",
              height: "46px",
              borderRadius: "5px",
              width: "50%",
              padding: "10px 16px 10px 16px",
              cursor: "pointer"
            }}>
              Delete
            </Button>
            <Button onClick={this.handleCloseDeleteDialog} data-test-id="handleConfirmDelete" style={{
              alignItems: "center",
              fontSize: "15px",
              justifyContent: "center",
              fontWeight: 500,
              display: "flex",
              color: "#375280",
              backgroundColor: "white",
              border: "1px solid #CCBEB1",
              height: "46px",
              borderRadius: "5px",
              width: "50%",
              padding: "10px 16px 10px 16px",
              cursor: "pointer"
            }} >
              Cancel
            </Button>
          </DialogActions>
        </DiologcontentStyledMain>
      

<Modal
  open={this.state.openModel}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={styleModel}>
    <CustomDiv>
      <img
        src={cross}
        alt="cross"
        data-test-id="modelOne"
        onClick={() => this.setState({ openModel: false })}
      />
    </CustomDiv>
    <CustomH1 variant="h1">Select Plan</CustomH1>
    <div style={{ margin: "40px 49px 20px 50px" }}>

      {(() => {
        // Get plan names from serviceResponse
        const availablePlans = this.state.serviceResponce?.data?.map(
          (item:any) => item.attributes.name
        ) || [];

        return (
          <>
         
            <div>
              <h1
                style={{
                  ...webStyle.menuH1,
                  color:
                    availablePlans.includes("weekly_plan")
                      ? "grey"
                      : "#375280",
                  pointerEvents:
                    availablePlans.includes("weekly_plan")
                      ? "none"
                      : "auto",
                }}
                data-test-id="Manuaaly"
                onClick={() => {
                  if (!this.state.editPlan || this.state.selectedPlanForEdit === "weekly_plan") {
                    localStorage.setItem("selectedPlan", "Weekly Plan");
                    this.setState({ openMainForm: true, openModel: false });
                  }
                }}
              >
                Weekly Plan
              </h1>
              <hr style={webStyle.hrModel} />
            </div>

         
            <div>
              <h1
                style={{
                  ...webStyle.menuH1,
                  color:
                     availablePlans.includes("monthly_plan")
                      ? "grey"
                      : "#375280",
                  pointerEvents:
                    availablePlans.includes("monthly_plan")
                      ? "none"
                      : "auto",
                }}
                data-test-id="modelTwo"
                onClick={() => {
                  if (!this.state.editPlan || this.state.selectedPlanForEdit === "monthly_plan") {
                    localStorage.setItem("selectedPlan", "Monthly Plan");
                    this.setState({ openMainForm: true, openModel: false });
                  }
                }}
              >
                Monthly Plan
              </h1>
              <hr style={webStyle.hrModel} />
            </div>

           
            <div>
              <h1
                style={{
                  ...webStyle.menuH1,
                  color:
                    availablePlans.includes("quarterly_plan")
                      ? "grey"
                      : "#375280",
                  pointerEvents:
                    availablePlans.includes("quarterly_plan")
                      ? "none"
                      : "auto",
                }}
                data-test-id="quarterly"
                onClick={() => {
                  if (!this.state.editPlan || this.state.selectedPlanForEdit === "quarterly_plan") {
                    localStorage.setItem("selectedPlan", "Quarterly Plan");
                    this.setState({ openMainForm: true, openModel: false });
                  }
                }}
              >
                Quarterly Plan
              </h1>
              <hr style={webStyle.hrModel} />
            </div>
          </>
        );
      })()}

    </div>
  </Box>
</Modal>

                <div>
                 
                </div>   
                
      </ThemeProvider>
      // Customizable Area End
    );   
  }
}

// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      flexDirection: "column",
      gap: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontWeight: 400,
        fontFamily: "Lato, sans-serif",
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center",
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",

      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },

  },
});
const DialogContainerCreate = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 50px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center",
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",

      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },

  },
});
const DiologcontentStyled = styled(DialogContent)({
  '&.MuiDialogContent-root:first-child': {
    textAlign: 'center',
  },

  // MuiDialog-paperWidthSm
})
const DiologcontentStyledMain = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '450px',
    padding:'30px'
  },
  '& .MuiPaper-rounded':{
    borderRadius:'4px',

  }
  // MuiDialog-paperWidthSm
})
export const TitleContainer = styled(Box)({
  gap: 20,
  alignItems: "center",
  justifyContent: "flex-start",
  display: "flex",
  "& .title": {
    color: "#375280",
    fontSize: 24,
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
  },
});

const styleModel = {
  position: 'absolute' as 'absolute',
  borderRadius: "12px",
  border: "1px solid #CCBEB1",
  width: "450px",
  p: 4,
  boxShadow: 24,
  height: "434px",
  bgcolor: 'background.paper',
  padding:0,
  transform: 'translate(-50%, -50%)',
  left: '50%',
  top: '50%',
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const CustomBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Take up full viewport height
  textAlign: 'center',
  marginTop: '250px', // Remove any margin
  width:'100%'
});

const CustomBoxSearch = styled(Box)({
  alignContent: "center",
  flexWrap: "wrap",
  display: "flex",
  marginLeft:"auto",
});

const CustomBoxContent = styled(Box)({
  justifyContent:"space-between",
  cursor: "pointer",
  flexWrap: "wrap",
  display: "flex",
});
const CustomDiv= styled(Typography)({
    textAlign:'end'
})
const CustomH1 =styled(Typography)({
  textAlign:"center",
  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  lineHeight: "40px",
  fontWeight: 500,
  fontSize: "30px",
  color: "#375280",
})

const webStyle = {
  fileName:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#375280",
    fontSize: "14px",
  },
  backCsvBtn:{
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "18px",
    fontStyle: "normal !important",
    color: "#375280",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    border: "1px solid #CCBEB1",
    background: "#FFFFFF",
    height:'54px',
    width:'252px',
   },
  
 
  hrModel:{
    border:"1px solid #D9D9D9",
    marginTop:24,
    marginBottom:24
  },
  modelCross:{
    textAlign:"end"
  },
  menuH1:{
    cursor:"pointer",
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 600,
    fontSize: "20px",
    color: "#375280",
  },
 
 
  zaraImg:{
    border: "1px solid #E2E8F0",
    height: "107",
    width: "110",
  },
  h1First:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "22px",
    color: "#375280",
  },
  h1Sec:{
    margin:0,
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "22px",
    fontSize: "18px",
    color: "#375280",
  },
  h1Last:{
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontWeight: 700,
    fontSize: "22px",
    fontFamily: "Lato , sans-serif",
    color: "#059669",
    margin:0,
  },
  divofZara:{
    display:'flex',
  },
  contentDivConteiner:{

    marginTop: "25px",
    borderRadius: "4px",
  },
  contentHeartDiv:{
    width:"24px",
    boxShadow: "0px 2px 8px 5px #00000014",
    background: "#FFFFFF",
    borderRadius: "45px",
    marginBottom: 70,
    height:"24px",
    border: "10px solid #FFFFFF",
    marginLeft:'auto',
  },
  headerDiv: {
    marginBottom: "-5px",
    display: "flex",
  },
  tshirtImg:{
    marginBottom:12
  },
  btnSetting:{
    lineHeight: "24px",
    fontSize: "17px",
    fontWeight: 700,
    height: 56,
    borderRadius: "4px",
    border: "1px solid",
    color: "#FFFFFF",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    width: "240px",
    background: "#CCBEB1",
  },
  

  btnSetting1:{
    fontSize: "17px",
    borderRadius: "4px",
    lineHeight: "24px",
    fontWeight: 700,
    height: 43,
    border: "1px solid #ff000096",
    color: "#ff000096",
    fontStyle: "normal !important",
    marginLeft: '15px',
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    width: "200px",
    background: "white",
  },
  btnTshirt:{
    fontWeight: 700,
    borderRadius: "4px",
    lineHeight: "26px",
    border: "1px solid",
    color: "#FFFFFF",
    fontStyle: "normal !important",
    fontSize: "20px",
    marginLeft: -3,
    fontFamily: "Lato , sans-serif",
    height: 58,
    cursor: "pointer",
    width: "250px",
    background: "#CCBEB1",
  },
  divTshirt: {
    textAlign:'center',
    marginTop: "190px",
    marginBottom: "150px"
  },
  titleNotification: {
    fontStyle: "normal !important",
    lineHeight: "32px",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
    fontSize: "24px",
    color: "#375280",
  },
  mainDiv: {
    margin: "30px",
    marginTop: "20px"
  },
  titleOfDivShirtHeader: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 500,
    fontSize: "28px",
    color: "#375280",
  },
};
// Customizable Area End
