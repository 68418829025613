import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
interface CatalogueVariantSize {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
  };
};
interface INavigateTo {
  id: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  dummySizes: CatalogueVariantSize[],
  alertMsg: string;
  circularProgress: boolean;
  buyerStylish: boolean;
  advancedSearchData: {
    id: string;
    attributes: {
      primary_image: string;
      is_wishlist: boolean;
      name: string;
      description: string;
      primary_price: string
    }
  }[];
  page: number;
  perPage: number;
  search: string;
  paginateScroll: boolean;
  dummySizesPush: number[];
  filterColor: CatalogueVariantSize[];
  sortedCategoryLoading: boolean;
  gender: string;
  subCategoryLoading: boolean;
  sort: string;
  filters: {
    minPrice: number,
    maxPrice: number,
    sizes: number[],
    colors: number[],
    sort: string,
    category: string
  };
  value: number[];
  colorePush: number[];
  storesData: {
    id: string;
    attributes: {
      store_name: string 
    }
  }[];
  storeChecked: boolean;
  storeCheck: string[];
  buyerStorePage: number;
  buyerStoreLoading: boolean;
  favouriteLoading: boolean;
  currencySign: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSearchdataApiCallID: string = "";
  getSearchdataScrollApiCallID: string = "";
  deleteFavouriteApiCalledId: string = "";
  postFavouriteApiCalledId: string = "";
  getSizeApiCallID:string = "";
  getColorApiCallID: string = "";
  getBuyerStoreAPICallID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isAlert: false,
      alertType: 'success',
      favouriteLoading: false,
      value: [0, 100],
      alertMsg: '',
      circularProgress: false,
      page: 1,
      sort: "",
      perPage: 10,
      search: "",
      advancedSearchData: [],
      paginateScroll: true,
      dummySizes: [],
      buyerStylish: false,
      dummySizesPush: [],
      filterColor: [],
      sortedCategoryLoading: false,
      gender: '',
      storeCheck: [],
      subCategoryLoading: false,
      colorePush: [],
      currencySign: "$",
      filters: {
        minPrice: 0,
        maxPrice: 0,
        sizes: [],
        colors: [],
        sort: "",
        category: ""
      },
      buyerStorePage: 1,
      storesData: [],
      storeChecked: false,
      buyerStoreLoading: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("auth-token");
    const userDetails = await getStorageData("userRole", true);
    const stylishBuyer = await getStorageData("exploreBuyer", true);
    if(stylishBuyer) this.setState({buyerStylish: stylishBuyer})
    this.setState({
        token: token
    });
    if(userDetails?.currencyLogo){
      this.setState({
        currencySign: userDetails?.currencyLogo
      })
    }
    this.getSearchApiCallID();
    this.getDummySizes();
    this.getColorApiCall();
    this.getBuyerStore();
    const dataBox = document.getElementById('scrollableDiv') as HTMLElement;
    dataBox.addEventListener('scroll', this.handleScroll);

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSearchdataApiCallID) {
        if(responseJson?.data){
            this.setState(prev => {
              return {
              advancedSearchData: responseJson.data,
               page: prev.page + 1,
               circularProgress: false
           }
        });       
        }else if(responseJson?.errors){
          this.setState({
            advancedSearchData: [], circularProgress: false,
            page: 1
          })
        }
        this.setState({
          circularProgress: false
        })
        }else if(apiRequestCallId === this.getSearchdataScrollApiCallID){
            if(responseJson?.data){
                const arrTrendList = [...this.state.advancedSearchData, ...responseJson.data];
                responseJson?.data?.length > 0 && this.setState({ currencySign: responseJson?.data[0]?.attributes?.currency_logo})
                this.setState(prev => {
                  return {
                    advancedSearchData: [...arrTrendList],
                   page: prev.page + 1,
                   circularProgress: false,
               }
            });       
            }else if(responseJson?.errors){
                this.setState({
                    circularProgress: false,
                    paginateScroll: false
                })
            } 
        }else if(apiRequestCallId === this.deleteFavouriteApiCalledId){
            if(responseJson.error){
                this.setState({
                  isAlert: true,
                  alertMsg: responseJson.error,
                  alertType: "error"
                })
              }else{
                this.setState({
                  isAlert: true,
                  alertMsg: `${configJSON.removeFavTitle}`,
                  alertType: "success"
                });
              }
              this.setState({
                favouriteLoading: false
              })
              this.getSearchApiCallID();
        }else {
          this.handleApiResponse(apiRequestCallId, message)
        }
    // Customizable Area End
  }

  // Customizable Area Start
  handleApiResponse = (apiRequestCallId: string, message:Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
     if(apiRequestCallId === this.postFavouriteApiCalledId){
      if(responseJson?.error){
                this.setState({
                  isAlert: true,
                  alertType: "error",
                  alertMsg: responseJson.error
                });
              }else if(responseJson.errors?.length > 0 && responseJson.errors[0]?.token){
                this.setState({
                  isAlert: true,
                  alertType: "error",
                  alertMsg: `${responseJson.errors[0].token}`,
                });
              }else{
                  this.setState({
                    isAlert: true,
                    alertType: "success",
                    alertMsg: `${configJSON.addFavTitle}`,
                  });
                }
                this.setState({
                  favouriteLoading: false
                })
              this.getSearchApiCallID();
    }else if(apiRequestCallId === this.getSizeApiCallID){
      if(responseJson?.data){
        this.setState({
          dummySizes: responseJson?.data
        })
      }
    }else if(apiRequestCallId === this.getColorApiCallID){
      if(responseJson?.data){
        this.setState({
          filterColor: responseJson?.data
        })
      }
    }else if(apiRequestCallId === this.getBuyerStoreAPICallID){
      this.setState({
        storesData: responseJson?.data,
        buyerStoreLoading: false
      });
    }
  };

  handleCondition = (condition: any , trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta 
  }

  getColorApiCall = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getColorApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.catalogues_variants_colors
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
};
  changeSearch = (value: string) => {
    this.setState({
        search: value,
        page: 1
    }, () => this.getSearchApiCallID())

  }

  handleGenderChange = (event: React.ChangeEvent<{}>) => {
    const value = (event.target as HTMLInputElement).value;
    this.setState({
      gender: value,page: 1
    }, ()=> this.getSearchApiCallID());
}
handleClearAll = () => {
  this.setState({
    filters: {
      minPrice: 0,
      maxPrice: 0,
      sizes: [],
      colors: [],
      sort: "",
      category: ""
    },gender: "", value: [0,100], colorePush: [], dummySizesPush: [], storeCheck: [], search: "", page: 1
  }, ()=> this.getSearchApiCallID())
}
handleSelectAll = (checkedValue: string) => {
  if(this.state.storeCheck.includes(checkedValue)){
    const filteredValue = this.state.storeCheck.filter(ele => ele !== checkedValue);
    this.setState({
      storeCheck : filteredValue, page: 1
    }, () => this.getSearchApiCallID())

  }else{
   this.setState((prevState) => ({
    storeCheck: [...prevState.storeCheck, checkedValue],
    page: 1
   }), () => this.getSearchApiCallID())
  }
}

handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
  const newdata: number[] = [...this.state.value];
  newdata[index] = parseInt(event.target.value.replace(/[^0-9.]/g, '')) | 0;
  this.setState((prevState) => ({
    filters: {...prevState.filters, 
      minPrice: newdata[0],
      maxPrice: newdata[1]
    },
    value: newdata, page: 1
  }), () => this.getSearchApiCallID())
};
handleColorClick = (colorId: number) => {
  let newlangColore: number[] = []
  if (this.state.colorePush.includes(colorId)) {
      newlangColore = this.state.colorePush.filter(value => value !== colorId)
  } else {
      newlangColore = [...this.state.colorePush, colorId]
  }
  this.setState({ colorePush: newlangColore, page: 1 }, () => this.getSearchApiCallID());
}

handleSortRadioChange = (value : string) => {
  this.setState((prevState) => ({
    filters: {
      ...prevState.filters,
      sort: value ,
    },
    page: 1
  }), () => this.getSearchApiCallID());
}

handleSizeClick = (sizeId: number) => {
  let newlangSize: number[] = []
  if (this.state.dummySizesPush.includes(sizeId)) {
      newlangSize = this.state.dummySizesPush.filter(value => value !== sizeId)
  } else {
      newlangSize = [...this.state.dummySizesPush, sizeId]
  }

  this.setState({ dummySizesPush: newlangSize, page: 1 }, ()=> this.getSearchApiCallID());
}

  getDummySizes = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSizeApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.catalogues_variants_sizes
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
};

getBuyerStore = async () => {
  const token = await getStorageData("auth-token");
  this.setState({
    buyerStoreLoading: true
  })
  const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
  };
  const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getBuyerStoreAPICallID = requestMessageList.messageId;
  requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
  );
  requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBuyerStoreApiCallID}?page=${this.state.buyerStorePage}&per_page=${16}`
  );
  requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessageList.id, requestMessageList);
};

  handleFavouriteApi = async (event: React.MouseEvent,favouriteId: string, fav: boolean) => {
    event?.stopPropagation()
    this.setState({
      page: 1
    })
    if (fav) {
      this.handleDeleteWishlist(favouriteId);
    } else {
      this.handleAddWishlistApiCall(favouriteId);
    }
  }

  handleDeleteWishlist = async (favouriteId?: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    this.setState({
      favouriteLoading: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFavouriteApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleAddWishlistApiCall = async (favouriteId?: string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    this.setState({
      favouriteLoading: true
    })
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const httpBody = {
      data: {
        favouriteable_id: favouriteId
      }
    };
  
    this.postFavouriteApiCalledId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFavouriteApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    if(this.state.paginateScroll && (dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight)){
        this.getSearchApiPaginateScroll();
    };
  };

  getSearchApiPaginateScroll = async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
    this.setState({
        circularProgress: true,
    })
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchdataScrollApiCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getSearchApiEndPoint}?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}${this.getFilterUri(this.state.filters)}&currency=${this.state.currencySign === "$" ? "dollar" : "dinar"}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }

   getFilterUri = (filters: any) => {
          let query = "";
          const addFilter = (key: string, value?: string | string[] | null) => {
            if (value) {
              query += `&${key}=${value}`;
            }
          };
      
      addFilter("min_price", filters.minPrice);
      addFilter("max_price", filters.maxPrice);
      addFilter("catalogue_variant_size_ids[]", filters.sizes?.join(","));
      addFilter("catalogue_variant_color_ids[]", filters.colors?.join(","));
      addFilter("sort", filters.sort);
      addFilter("category_ids[]", this.state.gender);  
      addFilter("catalogue_variant_size_ids[]", this.state.dummySizesPush.join(","));
      addFilter("catalogue_variant_color_ids[]", this.state.colorePush.join(","));
      addFilter("store_ids[]", this.state.storeCheck.join(","));
            
      return query;
    };

  getSearchApiCallID = async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
      token: token,
    };
    this.setState({
      circularProgress: true,
      paginateScroll: true
    })
    
      const updateUrl = `${configJSON.getSearchApiEndPoint}?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}${this.getFilterUri (this.state.filters)}&currency=${this.state.currencySign === "$" ? "dollar" : "dinar"}`;

    const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getSearchdataApiCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), updateUrl
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  navigateTo = async ({
    id,
    props,
    screenName,
    raiseMessage,
}: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
}
  // Customizable Area End
}
